<div class="content-space">
  <h2>Híreink</h2>
  <div class="divider"></div>
  <div class="flex flex-col md:flex-row justify-between gap-8 md:mx-4 mt-8 md:mt-12" *ngFor="let box of [0, 1]; index as i">
    <div class="w-full md:w-1/2 p-4 relative flex flex-col justify-between bg-neutral-100" *ngFor="let news of newsList | slice: i*2:i*2+2">
      <h4 class="absolute top-[-16px] font-bold p-2 bg-tertiary-light">{{news.title}}</h4>
      <div class="grid md:grid-cols-5 h-full">
        <div [ngClass]="news.image ? 'col-span-3' : 'col-span-5'">
          <p class="mt-4 py-4" [innerHtml]="news.description"></p>
          <a class="text-primary underline" *ngIf="news.link" [href]="news.link">Részletek</a>
        </div>
        <div class="col-span-2 ml-4 bg-center bg-cover" [style.background-image]="'url(' + news.image + ')'" *ngIf="news.image">
        </div>
      </div>
      <p class="text-xs text-gray-500 w-full flex justify-end mt-2">{{ news.date }}</p>
    </div>
  </div>
</div>
