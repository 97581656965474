<div class="profile">
  <div class="header">
    <div class="image"></div>
    <div class="info">
      <h2>Enesei Rita</h2>
      <div class="divider"></div>
      <p class="flex items-center"><img src="assets/icons/doctor.png" width="20" alt="doctor"/>gyógytornász</p>
      <p><a href="tel:+36308336933"><img src="assets/icons/phone.png" alt="phone" width="20"/> +3630/833-6933</a>
      </p>
    </div>
  </div>
  <div class="text-content">
    <p>Semmelweis Egyetemen végeztem 2019-ben tanulmányaimat, azután rögtön a Fejér Megyei
      Szent György Egyetemi és Oktató Kórházban kezdtem el dolgozni. Sok osztályon szereztem
      tapasztalatot, de legfőképpen a traumatológia területén mélyültem el. Úgy éreztem, hogy az
      állami ellátás területén már nem találtam a kihívást, ezért váltottam a magán ellátásra.
      <br/>Kiskorom óta életem nagy részét teszi ki a sport, mely nem csak kitartást és küzdeni vágyást
      adott, hanem akarást, hogy minél jobb legyek az élet egyéb területein - szakmámat is
      beleértve -. (Emiatt lettem gyógytornász és azóta is ezen a területen mélyítem tudásomat
      folyamatosan.)<br/><br/> Hiszem, hogy elsősorban mi tudunk saját magunkon segíteni a gyógyulás
      folyamán. Ehhez szeretnék segítséget nyújtani és megmutatni hogyan tehetjük ezt meg.
      Jelenleg egy sportegyesületnél dolgozom, valamint az EREF fizioterápiában.</p>
    <p>Amiben segítségére lehetek:</p>
    <ul>
      <li>Mozgásszervi problémák (derékfájdalom, nyakfájdalom)</li>
      <li>Műtét előtti felkészítés</li>
      <li>Műtét utáni rehabilitáció</li>
      <li>Sportsérülések utáni rehabilitáció, sportsérülések megelőzése</li>
      <li> Post-COVID ellátás</li>
    </ul>
    <p>Továbbképzések:</p>
    <ul>
      <li>Kinesio-tape</li>
      <li>Neurodimamika</li>
      <li>Sportedző (atlétika)</li>
      <li>Minősített McKenzie terapeuta (McKenzie A, B, C, D)</li>
    </ul>
  </div>
</div>
