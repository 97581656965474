<div class="content-space">
<h2>
    Torna nyugdíjasoknak
  </h2>
  <div class="divider"></div>
  <div>
    Minden <b>KEDDEN 14:00 - 14:45-ig*</b> egész testet átmozgató, csoportos órát tartunk nyugdíjasoknak.
    <br/>
    <br/><b>Helyszín:</b> Felsővárosi közösségi ház (8000 Székesfehérvár, Havranek u. 27.)
    <br/><b>Egy alkalom:</b> 1500 Ft
    <br/><b>4 alkalmas bérlet:</b> 5000 Ft<br/>
    <br/>
    <p>Bármilyen kérdés esetén keressen minket bizalommal:
      <a href="tel:+36308336933">➡ +3630/833-6933</a>
      <a href="mailto:fizioeref@gmail.com">➡ fizioeref@gmail.com</a>
    </p>
    <br/>
    <span class="text-sm">* Bejelentkezés nem szükséges</span>
  </div>
</div>
