<div class="content content-space">
  <a class="box" href="https://www.google.com/maps/place/Sz%C3%A9kesfeh%C3%A9rv%C3%A1r,+Budai+%C3%BAt+46,+8000/@47.1912739,18.4191239,17z/data=!4m6!3m5!1s0x4769f7bb2e5d1509:0x6ae34c47e1e70c51!8m2!3d47.1909896!4d18.4202826!16s%2Fg%2F11c22lp1nm?entry=ttu">
    <img src="assets/icons/location.png" alt="location">
    <div>
      <h2>Helyszín</h2>
      <p>Székesfehévári magánrendelőnk címe.</p>
    </div>
  </a>
  <a class="box" routerLink="foglalas">
    <img src="assets/icons/calendar.png" alt="calendar">
    <div>
      <h2>Időpontfoglalás</h2>
      <p>Gyógytorna és masszázs alkalomra időpont foglalása egyszerűen.</p>
    </div>
  </a>
  <a class="box" (click)="scrollTo()">
    <img src="assets/icons/question.png" alt="question">
    <div>
      <h2>Kérdése van?</h2>
      <p>Segítünk, forduljon hozzánk bizalommal.</p>
    </div>
  </a>
</div>
