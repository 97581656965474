import {Component, OnInit} from '@angular/core';
import {NewsService} from "../../core/news.service";
import {News} from "../../shared/types/types";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  newsList: News[] = [];
  constructor(private newsService: NewsService) {
  }

  ngOnInit() {
    this.getNews();
  }

  async getNews() {
    this.newsList =( await this.newsService.getNewsFromContentful()).reverse().slice(0,4);
  }

}
