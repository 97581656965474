import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AnalyticsService} from "./core/analytics.service";
import {lastValueFrom} from "rxjs";
import {MetaService} from "./core/meta.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'eref-frontend';

  constructor(private activatedRoute: ActivatedRoute, private analyticsService: AnalyticsService, private metaService: MetaService) {
    this.metaService.updateMetaTags('EREF fizioterápia - Gyógytorna Székesfehérváron', '/assets/images/og-image-t.jpg', 'https://www.fizioeref.hu');

    const url = new URL(window.location.href);
    const gclid =  url.searchParams.get("gclid") || '';
    const fbclid =  url.searchParams.get("fbclid") || '';

    sessionStorage.setItem('gclid', gclid);
    sessionStorage.setItem('fbclid', fbclid);
  }

  ngOnInit() {
    const fbc = sessionStorage.getItem('fbclid');

    if(!!fbc) {
      setTimeout(async () => {
        try{
          const sendFacebookEvent$ = this.analyticsService.sendFacebookEvent('ViewContent', fbc);
          await lastValueFrom(sendFacebookEvent$);
        } catch (e) {
          console.log(e);
        }
      },15000);
    }
  }
}
