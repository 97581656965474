<div class="content-space">
  <h2>Áraink</h2>
  <div class="divider"></div>
  <div class="price">
    <div class="row">
      <p>Első alkalom – betegvizsgálat,
        állapotfelmérés (45-60 perc)</p>
      <p>10 000 Ft</p>
    </div>
    <div class="row">
      <p>Gyógytorna kezelés (terápia típusától függetlenül, <b>45 perc</b>)
        - a kezelés időtartama a beteg állapotától
        függően ettől eltérhet</p>
      <p>8 000 Ft</p>
    </div>
    <div class="row">
      <p>Kinesiotape felhelyezés</p>
      <p>2 000 Ft</p>
    </div>
    <div class="row">
      <p>Dynamic tape felhelyezés</p>
      <p>3 000 Ft</p>
    </div>
    <div class="row">
      <p>Masszázs (30 perc)</p>
      <p>6 000 Ft</p>
    </div>
    <div class="row">
      <p>Masszázs (60 perc)</p>
      <p>9 000 Ft</p>
    </div>
    <div class="row">
      <p>Térdmozgató (CPM) gép (napi díj)<br/>
      <span class="text-xs">Székesfehérváron kívül + 80 Ft/km</span>
      </p>
      <p>1 700 Ft</p>
    </div>
    <div class="row">
      <p>Gerincjóga - egyéni (60 perc)</p>
      <p>9 000 Ft</p>
    </div>
    <div class="row">
      <p>Gerincjóga - páros (60 perc)</p>
      <p>16 000 Ft</p>
    </div>
    <div class="row">
      <p>Torna nyugdíjasoknak - 1 alkalom</p>
      <p>1 500 Ft</p>
    </div>
    <div class="row">
      <p>Torna nyugdíjasoknak - 4 alkalmas bérlet</p>
      <p>5 000 Ft</p>
    </div>
  </div>
  <p class="addition-text">* Az árváltoztatás jogát fenntarjuk.</p>

  <h2>Általános információk</h2>
  <div class="divider"></div>
  <div class="more">
    <b>Mit hozzak magammal a kezelésre?</b>
    <ul>
      <li>Az első alkalommal- amennyiben rendelkezésre állnak<br/>
        o Orvosi leletek a jelenlegi problémával kapcsolatban<br/>
        o Dokumentáció a korábbi kezelésekről<br/>
        o Képalkotó vizsgálatok eredményei, szöveges leírás
      </li>
      <li>Törülköző</li>
      <li>Kényelmes öltözet – a tornatermen belül átöltözésre van lehetőség</li>
    </ul>
    <br/>
    <b>Mennyivel előbb szükséges érkeznem a kezelésre?</b>
    <p class="my-4">A csoportosulás elkerülése
      érdekében, a megadott időpont előtt maximum 5-10 perccel érkezzenek!</p>
    <br/>
    <b>Mi a teendő, ha mégsem tudok megjelenni a megbeszélt időponton?</b>
    <ul>
      <li>Kérjük mindenképp jelezze, a lehető leghamarabb, valamely elérhetőségünk egyikén.</li>
      <li>Amennyiben online foglalta időpontját, úgy azt az e-mailben elküldött link
        segítségével mondhatja le, az időpont előtt maximum 24 órával.
      </li>
    </ul>
    <br/>
  </div>

</div>
