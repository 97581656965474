import {SafeResourceUrl} from "@angular/platform-browser";

export class BlogPost {
  title: string = '';
  description: string = '';
  content: string = '';
  image: string = '';
  id: number = 0;

  constructor(title: string, description: string, content: string, image: string, id: number) {
    this.title = title;
    this.description = description;
    this.content = content;
    this.image = image;
    this.id = id;
  }

}

export class Service {
  label: string;
  description: string;
  order: number;

  constructor(label: string, description: string, order: number) {
    this.label = label;
    this.description = description;
    this.order = order;
  }
}

export class Video {
  title: string;
  description: string;
  link: string | SafeResourceUrl;
  categories: string[];

  constructor(title: string, description: string, link: string, categories: string[]) {
    this.title = title;
    this.link = link;
    this.description = description;
    this.categories = categories;
  }
}

export type FacebookEventName = 'CompleteRegistration' | 'Contact' | 'ViewContent';

export class News {
  title: string;
  description: string;
  link: string | SafeResourceUrl | undefined;
  date: string;
  image: string | undefined;

  constructor(title: string, description: string, date: string, link: string | SafeResourceUrl | undefined, image: string | undefined) {
    this.title = title;
    this.description = description;
    this.date = date;
    this.link = link;
    this.image = image;
  }
}
