<div class="introduction content-space">
  <div class="images">
    <img *ngFor="let i of [0,1,2,3,4,5]" [src]="'assets/images/slides/'+ i + '.webp'"
         [ngClass]="{'show': i === this.slideIndex}"
         [alt]="'slide_image_' + i"/>
  </div>
  <div class="text">
    <h2>Rólunk</h2>
    <div class="divider"></div>
    <p>
      Az EREF fizioterápia - gyógytorna magánrendelő, Székesfehérváron várja a
      kedves pácienseket. Mozgásterápiás kezeléseink során fontos számunkra, hogy
      ne vég nélküli, érdemi megoldást nem nyújtó terápiákat alkalmazzunk, hanem az
      okokat feltárva megoldást kínáljunk az adott problémára, így segítve a hozzánk
      forduló pácienseink mielőbbi gyógyulását. Segítségére lehetünk akár a prevenció
      (megelőzés), akár egy műtét vagy megváltozott állapot miatti rehabilitációs
      folyamat során.<br/><br/>
      Rendelőnkben egyéni kezeléseket alkalmazunk, így a hozzánk forduló
      pácienseink maximális figyelmet kapnak.
      Amennyiben úgy gondolja, hogy segítségére lehetünk, vagy szolgáltatásainkkal
      kapcsolatban kérdése merülne fel, keressen minket bizalommal valamely
      elérhetőségünk egyikén!
    </p>
    <app-services></app-services>
  </div>
</div>
