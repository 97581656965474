<div class="mt-8">
  <h2>Szolgáltatásaink</h2>
  <div class="divider"></div>
  <ul>
    <li><a class="hover:underline" routerLink="szolgaltatasok/egyeni">Egyéni gyógytorna</a></li>
    <li><a class="hover:underline" routerLink="szolgaltatasok/csoportos">Nyugdíjas torna</a></li>
    <li><a class="hover:underline" routerLink="szolgaltatasok/egyeni" [queryParams]="{open: 9}">Gerincjóga</a></li>
    <li><a class="hover:underline" routerLink="szolgaltatasok/egyeni" [queryParams]="{open: 8}">Masszázs</a></li>
    <li><a class="hover:underline" routerLink="szolgaltatasok/cpm">Térdmozgató gép bérlése</a></li>
  </ul>
</div>
