<div class="content-space">
  <h2>
    Térdmozgató gép bérlése
  </h2>
  <div class="divider"></div>
  <div class="sm:flex">
    <div>
      <p>A Kinetic Spectra Essential egy passzív térdmozgató gép amely a nyújtást és a hajlítás teszi lehetővé -10° és a
        120° között.</p>
      <h3>Mikor érdemes és meddig?</h3>
      <p> Gyógytorna kiegészítő terápiájaként a rehabilitáció korai fázisában lehet használni, amíg a aktívan a 120°-os
        térd
        hajlítás nem kivitelezhető.</p>
    </div>
    <img src="assets/images/cpm.jpeg" alt="cpm" class="my-8 sm:m-0"/>
  </div>
  <h3>Mi a célja?</h3>
  ✔️ gyógytorna kiegészítése a rehibilitáció korai fázisában<br/>
  ✔️ segíti az ízület mozgatását egyenletes tempóban, amely hozzájárul a mozgásterjedelem növekedéséhez<br/>
  ✔️ csökkentheti a fájdalmat<br/>
  ✔️ a keringés javítása által használatával megelőzhető a mélyvénás trombózis<br/>

  <h3>Miért jó?</h3>
  ➡ otthonában, kényelmesen, könnyen használható<br/>
  ➡ nehezen mozgó ízületeknél a folyamatos egyenletes mozgás sokat segíthet a mozgástartomány növeléséhez<br/>
  ➡ műtét után azonnal elkezdhető<br/>

  <h3>Hatásai</h3>
  <ul>
    <li>Megelőzi az ízületi merevséget</li>
    <li>Serkenti a keringést</li>
    <li>Fájdalomcsillapítás</li>
    <li>Vénás trombózis megelőzése</li>
    <li>Az eszközzel műtét után rögtön elkezdhető az ízület mozgatása</li>
    <li>Segíti a duzzanat felszívódását</li>
    <li>Ízületi mozgásterjedelem hamarabb visszanyerhető</li>
  </ul>

  <h3>Indikációk</h3>
  <ul>
    <li>Térd és csípő protézis</li>
    <li>Térdízület körüli csonttörés</li>
    <li>Térdkalács törés</li>
    <li>Térdszalag sérülések/pótlások</li>
    <li>Térdízületi műtétek (porcvarrás, ín/szalag/kontraktúrák felszabadító beavatkozása)</li>
    <li>Térd extensor felszabadító beavatkozás</li>
    <li>Egyéb sérülések/műtétek, ahol az aktív térd hajlítás/nyújtás nehezen kivitelezhető</li>
  </ul>

  <h3>Kontraindikaciók (amikor nem javasolt)</h3>
  <ul>

    <li>Ízületi gyulladásos betegségek az aktív gyulladásos fázisában</li>
    <li>Köszvény</li>
    <li>Algodystrophia a gyulladásos fázisában</li>
    <li>Elfertőződött seb</li>
    <li>Csont beültetés</li>
    <li>Csontot érintő daganatos állapot</li>
    <li>Megállapítottad visszér gyulladás</li>
    <li>Myositis ossificans a quadriceps izomban (szilárd, kalcifikált képződmény, amely a szöveti sérülés helyén alakul
      ki)
    </li>
    <li>Fertőzéses ízületi gyulladás</li>
    <li>Csípő merevítés esetén</li>
    <li>Deformált ízületi felszínek</li>
    <li>Bénult végtag (akár tónustalan, akár spasztikus)</li>
    <li>Instabil törés</li>
  </ul>
  <p><b>A térdmozgató gép 195 cm feletti és 145 cm alatti embernek nem beállítható. 135 kg a maximális teherbírás.</b>
  </p>
  <br/>
  <p class="text-xl">Béreljen tőlünk térdmozgató gépet ➡ +36 30/833-6933</p>
</div>
