import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Title} from '@angular/platform-browser';
import {ServicesService} from "../../core/services.service";
import {Service} from "../../shared/types/types";

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit, AfterViewChecked {
  contentIndex: number = 0;
  services: Service[] = [];
  queryParam: number | undefined;
  isScrolledDown: boolean = false;

  constructor(private gaService: GoogleAnalyticsService, private titleService: Title, private servicesService: ServicesService) {
  }

  async ngOnInit() {
    this.titleService.setTitle('EREF fizioterápia - Szolgáltatások');
    // this.gaService.pageView('/szolgaltatasok', 'Szolgáltatások');

    this.services = await this.servicesService.getServiceList();

    const queryParam = new URL(window.location.href).searchParams.get("open");
    this.queryParam = queryParam ? +queryParam : undefined;
    this.queryParam && this.showContent(this.queryParam);
  }

  ngAfterViewChecked() {
    if (this.queryParam && !this.isScrolledDown) {
      const element = document.getElementById(`service-${this.queryParam}`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
        this.isScrolledDown = true;
      }
    }
  }

  showContent(index: number) {
    this.contentIndex === index ? this.contentIndex = 0 : this.contentIndex = index;
  }

}
