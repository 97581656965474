import { Injectable } from '@angular/core';
import { News} from "../shared/types/types";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import {BLOCKS} from "@contentful/rich-text-types";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private httpClient: HttpClient) { }

  async getNewsFromContentful(): Promise<News[]> {
    const newsList = await this.httpClient.get(
      'https://cdn.contentful.com/spaces/0yl50763pf02/environments/master/entries?access_token=q1y1kgfFDay3TtOOGa0_1AH4LzOld_soRc9yW41-LGs&content_type=content&fields.menu=Híreink&include=3').toPromise();

    const entries = (newsList as any).includes.Entry;
    return (newsList as any).items.slice(0,4).map((news: any) => {
        return news.fields.content.map((content: { sys: { id: string; }; }) => {
          const foundedNews = entries.find((item: {
            sys: { id: string; };
          }) => item.sys.id === content.sys.id)


          const imageId = foundedNews.fields.image?.sys.id;
          const image = imageId ? (newsList as any).includes.Asset.find((asset: any) => asset.fields.file.url.includes(imageId)) : undefined;
          const description = documentToHtmlString(foundedNews.fields.description, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
              [BLOCKS.HEADING_2]: (node, next) => `<br /><br /><h2>${next(node.content)}</h2>`
            }
          });

          return new News(foundedNews.fields.title, description, foundedNews.fields.date, foundedNews.fields.link, image?.fields?.file.url);
        });
      }
    )[0];
  }
}
